<template>
<!-- 企业入驻 -->
  <div class="BGS">
      <Header></Header>
      <QSettleIn></QSettleIn>
      <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header"
import QSettleIn  from "@/components/QSettleIn"
import Footer from "@/components/Footer"
export default {
    data(){
        return{

        }
    },
    methods:{

    },
    components:{
      Header,
      QSettleIn,
      Footer
    },
}
</script>

<style scoped>
.BGS {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}
</style>