<template>
  <!-- 企业入驻 -->
  <div class="Entry_Enterprises">
    <div class="Enterprise_Information">
      <div class="Enterprise_Information_left"></div>
      <div class="Enterprise_Information_right">企业信息</div>
    </div>
    <div class="Enterprise_LoGo">
      <div class="Enterprise_LoGo_title">
        <span>＊</span>
        <span>企业/品牌LOGO</span>
        <el-upload class="Enterprise_LoGo_img" action="https://tsadmin.qudong.com/ApiUpload/upload_many"
          :headers="headers" :show-file-list="false" multiple name="file[]" :on-success="successAgreements"
          :before-upload="beforeRef">
          <img v-if="imageUrls" :src="imageUrls" class="Enterprise_LoGos">
          <i v-else class="el-icon-plus Enterprise_LoGo_img_icon"></i>
        </el-upload>
      </div>
    </div>
    <div class="Enterprise_ipt">
      <span>＊</span>
      <span>企业简称</span>
      <input type="text" class="ipt1" v-model="Abbreviation" placeholder="">
    </div>
    <div class="border"></div>
    <!-- 账号信息 -->
    <div class="account_information">
      <div class="Enterprise_Information">
        <div class="Enterprise_Information_left"></div>
        <div class="Enterprise_Information_right">账号信息</div>
      </div>
    </div>
    <div class="Enterprise_LoGo">
      <div class="Enterprise_LoGo_title">
        <span>＊</span>
        <span>绑定手机号码</span>
        <input type="text" v-model="phone1" class="ipt1">
      </div>
    </div>
    <input type="text" v-model="sendCode1" class="code_ipt">
    <button class="obtain_code" @click="sendCode" :disabled="smsFlag">{{sendTime}}</button>
    <div class="border"></div>
    <!-- 企业资料 -->
    <div class="Enterprise_information">
      <div class="Enterprise_Information">
        <div class="Enterprise_Information_left"></div>
        <div class="Enterprise_Information_right">企业资料</div>
      </div>
      <div class="Enterprise_information_bottom">
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>公司名称</span>
          </div>
          <input type="text" v-model="input1" class="ipt2" placeholder="">
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>营业执照注册号</span>
          </div>
          <input type="text" v-model="input2" class="ipt2" placeholder="">
          <span class="title_word">请输入15位营业执照注册号或18位统一社会信用代码</span>
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>法人姓名</span>
          </div>
          <input type="text" v-model="input3" class="ipt2" placeholder="">
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>官方网站</span>
          </div>
          <input type="text" v-model="input4" class="ipt2" placeholder="">
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>所属行业</span>
          </div>
          <el-select v-model="liveUserName" @change="selectChange" placeholder="请选择">
            <el-option v-for="(item,index) in options" :key="index" :label="item.title" :value="item">
            </el-option>
          </el-select>
        </div>
        <!-- <div class="Its_brands" v-for="(L_item,L_index) in lists" :key="L_index">
          <div class="Its_brands_left">
            <span v-if="L_index==0">＊</span>
            <span v-if="L_index==0">旗下品牌</span>
          </div>
          <div class="Its_brands_right">
            <div class="brands_name">
              <div class="brands_name_top">
                <span>品牌名称</span>
                <input type="text" @input="getinput($event,L_index)" class="ipt_name">
              </div>
              <div class="brands_name_top">
                <span>所属行业</span>
                <el-select v-model="liveUserID" @change="selectChange($event,L_index)" placeholder="请选择">
                  <el-option v-for="(item,index) in options" :key="index" :label="L_item.hangye" :value="item.title">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="Its_brands_bottom">
            <el-upload class="avatar-uploader" action="https://tsadmin.qudong.com/ApiUpload/upload_many"
              :headers="headers" :show-file-list="false" multiple name="file[]"
              :on-success="(res,file)=>{successAgreement(L_index,res,file)}" :before-upload="beforeRef">
              <img v-if="L_item.logo" :src="L_item.logo" class="avatar_logo">
              <i v-else class="el-icon-plus avatar-uploader-icon_logo"></i>
            </el-upload>
          </div>
        </div> -->
        <!-- <div class="Add_information" @click="Add_information">
          <img src="../assets/img/Add_icon.png" alt="">
          <span>添加新品牌</span>
        </div> -->
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>客服热线</span>
          </div>
          <input type="text" v-model="input5" class="ipt2" placeholder="">
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>公司地址</span>
          </div>
          <input type="text" v-model="input6" class="ipt2" placeholder="">
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>公司简介</span>
          </div>
          <textarea name="" id="" v-model="input7" class="textarea" cols="30" rows="10"></textarea>
        </div>
        <div class="border"></div>
      </div>
    </div>
    <!-- 附件上传 -->
    <div class="Enterprise_Information">
      <div class="Enterprise_Information_left"></div>
      <div class="Enterprise_Information_right">附件上传</div>
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left1">
        <span>＊</span>
        <span>法人身份证扫描件:</span>
      </div>

      <el-upload class="avatar-uploader_zheng" action="https://tsadmin.qudong.com/ApiUpload/upload_many"
        :headers="headers" :show-file-list="false" multiple name="file[]" :on-success="successAgreement"
        :before-upload="beforeRef">
        <img v-if="imageUrl1" :src="imageUrl1" class="avatar_zheng">
        <i v-else class="el-icon-plus avatar-uploader-icon_zheng"></i>
      </el-upload>
      <el-upload class="avatar-uploader_fan" action="https://tsadmin.qudong.com/ApiUpload/upload_many" :headers="headers"
        :show-file-list="false" multiple name="file[]" :on-success="successAgreement1" :before-upload="beforeRef">
        <img v-if="imageUrl2" :src="imageUrl2" class="avatar_fan">
        <i v-else class="el-icon-plus avatar-uploader-icon_fan"></i>
      </el-upload>
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left">
        <span>＊</span>
        <span>认证协议</span>
      </div>
      <el-upload class="avatar-uploader_xy" action="https://tsadmin.qudong.com/ApiUpload/upload_many" :headers="headers"
        :show-file-list="false" multiple name="file[]" :on-success="successAgreement2" :before-upload="beforeRef">
        <img v-if="imageUrl3" :src="imageUrl3" class="avatar_xy">
        <i v-else class="el-icon-plus avatar-uploader-icon_xy"></i>
      </el-upload>
      <a href="https://tsadmin.qudong.com/鹰眼投诉企业认证公函.docx" download="鹰眼投诉企业认证公函.docx"><span class="title_word_bgs">《认证协议》点击下载</span></a>
      <span class="title_word"
        style="margin-left:0;">模板，按要求填写后，扫描或拍照上传</span>
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left">
        <span>＊</span>
        <span>营业执照扫描件</span>
      </div>
      <el-upload class="avatar-uploader_xy" action="https://tsadmin.qudong.com/ApiUpload/upload_many" :headers="headers"
        :show-file-list="false" multiple name="file[]" :on-success="successAgreement3" :before-upload="beforeRef">
        <img v-if="imageUrl4" :src="imageUrl4" class="avatar_xy">
        <i v-else class="el-icon-plus avatar-uploader-icon_xy"></i>
      </el-upload>
      <div class="D_title_word">请上传营业执照的清晰彩色原件扫描或数码照，在有效期内且年检章齐全。大小不能超过2M。支持格式：.jpg、.png、.jpeg</div>
    </div>
    <div class="border"></div>
    <!-- 入驻联系人信息 -->
    <div class="Enterprise_Information">
      <div class="Enterprise_Information_left"></div>
      <div class="Enterprise_Information_right">入驻联系人信息</div>
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left">
        <span>＊</span>
        <span>联系人</span>
      </div>
      <input type="text" v-model="input8" class="ipt1">
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left">
        <span>＊</span>
        <span>联系人电话</span>
      </div>
      <input type="text" v-model="phone2" class="ipt1">
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left">
        <span>＊</span>
        <span>验证码</span>
      </div>
      <input type="text" v-model="sendCode2" class="code_ipt1">
      <button class="obtain_code" @click="sendCodes" :disabled="smsFlag1">{{sendTime1}}</button>
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left">
        <span>＊</span>
        <span>邮箱</span>
      </div>
      <input type="text" v-model="input9" class="ipt1">
    </div>
    <div class="checks">
      <input type="checkbox" v-model="Checkbox" class="checkbox">
      <span>您已同意</span>
      <span>《用户协议》</span>
      <span>《隐私政策》</span>
    </div>
    <button class="Submit" @click="Submit">提交</button>
  </div>
</template>

<script>
import Axios from "axios";
import md5 from "js-md5";
import { HangYe_type, attestation_one } from "@/api/api.js";
let token = localStorage.getItem("token");
export default {
   metaInfo(){
     return{
        title: '企业入驻',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      headers: {
        token: token,
      }, //请求头传token
      index: "",
      options: [],
      liveUserID: "", //行业id
      liveUserName: "", //行业name
      imageUrl: "",
      // lists: [
      //   {
      //     name: "",
      //     logo: "",
      //     hangye: "",
      //   },
      // ],
      sendTime: "获取验证码",
      snsMsgWait: 120,
      phone1: "", //绑定手机号
      smsFlag: false,
      sendCode1: "", //验证码输入框
      input1: "", //输入框值1
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      input6: "",
      input7: "",
      input8: "",
      input9: "",
      imageUrls: "", //企业/品牌logo图片
      imageUrl1: "", //图片
      imageUrl2: "",
      imageUrl3: "",
      imageUrl4: "",
      sendCode2: "", //验证码输入框2
      phone2: "", //联系人电话
      sendTime1: "获取验证码",
      snsMsgWait1: 120,
      smsFlag1: false,
      Checkbox: false,
      Abbreviation: "", //企业简称ipt
    };
  },
  methods: {
    // 添加的品牌名称
    getinput(e, index) {
      var that = this;
      //console.log(e.target.value);
      //console.log(index);
      for (let i in that.lists) {
        if (i == index) {
          that.lists[i].name = e.target.value;
        }
      }
    },
    // 请求图片成功
    successAgreement(index, res, file) {
      var that = this;
      //console.log(res.data[0]);
      //console.log(index);
      for (let i in that.lists) {
        if (i == index) {
          that.lists[i].logo = res.data[0];
        }
      }
    },
    // 请求前限制上传条件
    beforeRef(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG || !isLt2M) {
        this.$message.error("上传文件只能是图片格式，且大小不能超过2M");
      }
      return isJPG, isLt2M;
    },
    // 行业分类展示接口
    HangYe_type() {
      var that = this;
      HangYe_type({}).then((res) => {
        //console.log(res);
        that.options = res.data.data;
      });
    },
    // 下拉选取的id和name
    selectChange(e) {
      var that = this;
      //console.log(e);
      this.liveUserID = e.id;
      this.liveUserName = e.title;
      //console.log(this.liveUserID, this.liveUserName);
      // var lists = that.lists;
      // for (let i in lists) {
      //   if (i == L_index) {
      //     this.options.map((item) => {
      //       if (item.title == e) {
      //         that.lists[i].hangye = item.title; //传值
      //         // this.ToviewForm.deptId = item.id;//传值
      //       }
      //     });
      //   }
      // }
    },
    // // 添加功能
    // Add_information() {
    //   var that = this;
    //   var lists = that.lists;
    //   lists.push({ name: "", logo: "", hangye: "" });
    //   //console.log(lists);
    //   that.lists = lists;
    // },

    // 获取验证码
    sendCode() {
      var that = this;
      let phone1 = that.phone1;
      //console.log(that.phone1);
      let md_phone = phone1.substring(7);
      const myreg = /^[1][3-9][0-9]{9}$/;
      if (!myreg.test(phone1)) {
        this.$message.error("电话号码格式不对");
        return;
      }
      Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/user/security_code",
        params: {
          phone_num: phone1,
          captcha: md5(md_phone),
        },
      }).then((res) => {
        //console.log(res);
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        var inter = setInterval(
          function () {
            (that.smsFlag = true),
              (that.sendTime = that.snsMsgWait + "s后重发"),
              (that.snsMsgWait = that.snsMsgWait - 1);
            if (that.snsMsgWait < 0) {
              clearInterval(inter);
              that.sendTime = "获取验证码";
              that.snsMsgWait = 120;
              that.smsFlag = false;
            }
          }.bind(this),
          1000
        );
      });
    },
    // 身份证正面图片
    successAgreement(res) {
      var that = this;
      //console.log(res.data[0]);
      that.imageUrl1 = res.data[0];
    },
    // 身份证反面图片
    successAgreement1(res) {
      var that = this;
      //console.log(res.data[0]);
      that.imageUrl2 = res.data[0];
    },
    // 协议
    successAgreement2(res) {
      var that = this;
      //console.log(res.data[0]);
      that.imageUrl3 = res.data[0];
    },
    // 营业执照扫描件
    successAgreement3(res) {
      var that = this;
      //console.log(res.data[0]);
      that.imageUrl4 = res.data[0];
    },
    // 企业/品牌LOGO
    successAgreements(res) {
      var that = this;
      //console.log(res.data[0]);
      that.imageUrls = res.data[0];
    },
    // 获取验证码2
    sendCodes() {
      var that = this;
      let phone2 = that.phone2;
      //console.log(that.phone2);
      let md_phone = phone2.substring(7);
      const myreg = /^[1][3-9][0-9]{9}$/;
      if (!myreg.test(phone2)) {
        this.$message.error("电话号码格式不对");
        return;
      }
      Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/user/security_code",
        params: {
          phone_num: phone2,
          captcha: md5(md_phone),
        },
      }).then((res) => {
        //console.log(res);
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        var inter = setInterval(
          function () {
            (that.smsFlag1 = true),
              (that.sendTime1 = that.snsMsgWait1 + "s后重发"),
              (that.snsMsgWait1 = that.snsMsgWait1 - 1);
            if (that.snsMsgWait1 < 0) {
              clearInterval(inter);
              that.sendTime1 = "获取验证码";
              that.snsMsgWait1 = 120;
              that.smsFlag1 = false;
            }
          }.bind(this),
          1000
        );
      });
    },

    // 提交
    Submit() {
      let that = this;
      let imageUrls = that.imageUrls; //企业/品牌logo
      let Abbreviation = that.Abbreviation.replace(/[ ]/g, ""); //企业简称     顺带过滤空格
      let phone1 = that.phone1; //绑定手机号好吗
      let input1 = that.input1; //公司名称
      let input2 = that.input2; //营业执照注册号
      let input3 = that.input3; //法人姓名
      let input4 = that.input4; //官方网站
      let input5 = that.input5; //客服热线
      let input6 = that.input6; //公司地址
      let input7 = that.input7; //公司简介
      let input8 = that.input8; //联系人
      let input9 = that.input9; //邮箱
      let liveUserID = that.liveUserID; //行业id
      let liveUserName = that.liveUserName; //所属行业名称
      let imageUrl1 = that.imageUrl1; //图片1
      let imageUrl2 = that.imageUrl2; //图片2
      let imageUrl3 = that.imageUrl3; //图片3
      let imageUrl4 = that.imageUrl4; //图片4
      let phone2 = that.phone2; //联系人电话
      if (!imageUrls) {
        this.$message.warning("LOGO不能为空");
      } else if (!Abbreviation) {
        this.$message.warning("企业简称不能为空");
      } else if (!phone1) {
        this.$message.warning("手机号码不能为空!");
      } else if (!that.sendCode1) {
        this.$message.warning("验证码不能为空");
      } else if (!input1) {
        this.$message.warning("公司名称不能为空");
      } else if (!input2) {
        this.$message.warning("营业执照注册号不能为空");
      } else if (!input3) {
        this.$message.warning("法人姓名不能为空");
      } else if (!input4) {
        this.$message.warning("官方网站不能为空");
      } else if (!liveUserName) {
        this.$message.warning("所属行业不能为空");
      } else if (!input5) {
        this.$message.warning("客服热线不能为空");
      } else if (!input6) {
        this.$message.warning("公司地址不能为空");
      } else if (!input7) {
        this.$message.warning("公司简介不能为空");
      } else if (!imageUrl1) {
        this.$message.warning("请上传身份证正面");
      } else if (!imageUrl2) {
        this.$message.warning("请上传身份证反面");
      } else if (!imageUrl3) {
        this.$message.warning("请上传认证协议");
      } else if (!imageUrl4) {
        this.$message.warning("请上传营业执照扫描件");
      } else if (!input8) {
        this.$message.warning("联系人不能为空");
      } else if (!phone2) {
        this.$message.warning("联系人电话不能为空");
      } else if (!that.sendCode2) {
        this.$message.warning("验证码不能为空");
      } else if (!input9) {
        this.$message.warning("邮箱不能为空");
      } else if (!this.Checkbox) {
        this.$message.warning("请您先勾选协议!");
      } else if (phone2 == phone1) {
        this.$message.warning("绑定手机号和联系人电话不能一致");
      } else {
        attestation_one({
          logo: imageUrls,
          brand_name: Abbreviation,
          legal_phone: phone1,
          title: input1,
          license_number: input2,
          legal_person: input3,
          website: input4,
          industry_id: liveUserID,
          industry_name: liveUserName,
          customer: input5,
          address: input6,
          one_info: input7,
          id_card_just: imageUrl1,
          id_card_bake: imageUrl2,
          authentication_protocol: imageUrl3,
          yingyezhizhao: imageUrl4,
          name: input8,
          phone: phone2,
          emails: input9,
        }).then((res) => {
          //console.log(res);
          if (res.data.code == 200) {
            localStorage.setItem("is_brand_user",1);
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            setTimeout("location.href='/'", 1000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      }
    },
  },
  mounted() {
    var that = this;
    that.HangYe_type();
  },
};
</script>

<style scoped>
.Entry_Enterprises {
  width: 1160px;
  background: #fff;
  margin: 20px auto;
  padding: 10px 20px 40px 20px;
}
.Enterprise_Information {
  display: flex;
  margin-top: 20px;
  align-items: center;
}
.Enterprise_Information_left {
  width: 4px;
  height: 22px;
  background: #1f72d9;
  margin-right: 10px;
}
.Enterprise_Information_right {
  color: #1f72d9;
  font-size: 22px;
  font-weight: bold;
}
.Enterprise_LoGo_title {
  font-size: 18px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.Enterprise_LoGo_title span:nth-child(1) {
  color: #ff4a4a;
}
.Enterprise_LoGo_title span:nth-child(2) {
  color: #333333;
}
.Enterprise_ipt {
  margin-top: 20px;
  margin-left: 30px;
}
.Enterprise_ipt span {
  font-size: 18px;
}
.Enterprise_ipt span:nth-child(1) {
  color: #ff4a4a;
}
.Enterprise_ipt span:nth-child(2) {
  color: #333333;
}

.ipt1 {
  padding-left: 15px;
  width: 328px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  margin-left: 15px;
}
.ipt2 {
  padding-left: 15px;
  width: 540px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  margin-left: 15px;
}
.border {
  margin-top: 25px;
  border-bottom: 1px solid #e8e8e8;
}

/* 账号信息 */
.account_information {
  margin-top: 20px;
}
.code_ipt {
  margin-left: 141px;
  margin-top: 20px;
  width: 150px;
  height: 40px;
  padding-left: 15px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
}
.obtain_code {
  margin-left: 20px;
  width: 124px;
  height: 40px;
  background: #1f72d9;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
}
/* 企业资料 */
.Enterprise_information_1 {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.title_left {
  width: 148px;
  text-align: right;
}
.title_left1 {
  width: 175px;
  text-align: right;
}
.title_left1 span {
  font-size: 18px;
}
.title_left1 span:nth-child(1) {
  color: #ff4a4a;
}
.title_left1 span:nth-child(2) {
  color: #333333;
}
.title_left span {
  font-size: 18px;
}
.title_left span:nth-child(1) {
  color: #ff4a4a;
}
.title_left span:nth-child(2) {
  color: #333333;
}
.title_word {
  margin-left: 20px;
  font-size: 16px;
  color: #999999;
}
.D_title_word {
  width: 530px;
  margin-left: 20px;
  font-size: 16px;
  color: #999999;
}
.title_word_bgs {
  color: #276cb5;
}
.Its_brands {
  display: flex;
  /* align-items: center; */
  margin-top: 20px;
}
.Its_brands_left {
  width: 148px;
  text-align: right;
  margin-top: 9px;
}
.Its_brands_left span {
  font-size: 18px;
}
.Its_brands_left span:nth-child(1) {
  color: #ff4a4a;
}
.Its_brands_left span:nth-child(2) {
  color: #333333;
}
.brands_name {
  margin-left: 20px;
}
.brands_name span {
  margin-right: 20px;
  font-size: 16px;
  color: #666666;
}
.brands_name .ipt_name {
  width: 312px;
  height: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.brands_name_top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
>>> .el-select {
  width: 314px;
  height: 40px;
  border-radius: 4px;
  margin-left: 15px;
}

>>> .el-icon-plus:before {
  content: none;
}
.avatar-uploader {
  width: 102px;
  height: 102px;
  margin-left: 57px;
  background-image: url("../assets/img/upload_img.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon_logo {
  font-size: 28px;
  color: #8c939d;
  width: 102px;
  height: 102px;
  line-height: 178px;
  text-align: center;
}
.avatar_logo {
  width: 102px;
  height: 102px;
  display: block;
  background: #fff;
}
.Add_information {
  display: flex;
  margin-left: 168px;
  align-items: center;
  width: 132px;
  margin-top: 10px;
  height: 40px;
  cursor: pointer;
  border: 1px solid #1f72d9;
  border-radius: 8px;
  color: #1f72d9;
}
.Add_information img {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-right: 10px;
}
.textarea {
  width: 540px;
  height: 110px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin-left: 15px;
  padding-left: 15px;
  padding-top: 10px;
  resize: none;
}
/* 正面 */
.avatar-uploader_zheng {
  width: 205px;
  height: 160px;
  border-radius: 6px;
  margin-left: 57px;
  background-image: url("../assets/img/zhengmian_icon_id.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.avatar-uploader_zheng .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader_zheng .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon_zheng {
  font-size: 28px;
  color: #8c939d;
  width: 205px;
  height: 160px;
  line-height: 160px;
  border-radius: 6px;
  text-align: center;
}
.avatar_zheng {
  width: 205px;
  height: 160px;
  border-radius: 6px;
  display: block;
  background: #fff;
}
/* 反面 */
.avatar-uploader_fan {
  width: 205px;
  height: 160px;
  border-radius: 6px;
  margin-left: 57px;
  background-image: url("../assets/img/fanmian_icon_id.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.avatar-uploader_fan .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader_fan .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon_fan {
  font-size: 28px;
  color: #8c939d;
  width: 205px;
  height: 160px;
  border-radius: 6px;
  line-height: 160px;
  text-align: center;
}
.avatar_fan {
  width: 205px;
  height: 160px;
  border-radius: 6px;
  display: block;
  background: #fff;
}
/* 认证协议 */
.avatar-uploader_xy {
  width: 120px;
  height: 124px;
  border-radius: 6px;
  margin-left: 57px;
  background-image: url("../assets/img/xiangji.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.avatar-uploader_xy .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader_xy .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon_xy {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 124px;
  line-height: 124px;
  border-radius: 6px;
  text-align: center;
}
.avatar_xy {
  width: 120px;
  height: 124px;
  border-radius: 6px;
  display: block;
}
.code_ipt1 {
  margin-left: 15px;
  width: 150px;
  height: 40px;
  padding-left: 15px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
}

.checks {
  margin-top: 40px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 18px;
  margin-right: 10px;
  position: relative;
}
.checks span:nth-child(3) {
  color: #1f72d9;
}
.checks span:nth-child(4) {
  color: #1f72d9;
}
.Submit {
  margin-top: 30px;
  margin-left: 15px;
  width: 320px;
  height: 48px;
  background: #1f72d9;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}
/* 企业/品牌 logo */
.Enterprise_LoGo_img {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  margin-left: 57px;
  background-image: url("../assets/img/xiangji.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.Enterprise_LoGo_img .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.Enterprise_LoGo_img .el-upload:hover {
  border-color: #409eff;
}
.Enterprise_LoGo_img_icon {
  font-size: 28px;
  color: #8c939d;
  width: 57px;
  height: 57px;
  line-height: 160px;
  border-radius: 50%;
  text-align: center;
}
.Enterprise_LoGos {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  display: block;
  background: #fff;
}
</style>